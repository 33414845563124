
import { defineComponent } from 'vue'
import BaseHeader from '@/components/base/BaseHeader.vue'
import OpenSourceAppItemVue from '@/components/OpenSourceAppItem.vue'
// eslint-disable-next-line @typescript-eslint/no-var-requires
import ItemsData from '@/data/template-items.json'

export default defineComponent({
  name: 'CliTemplate',
  components: {
    BaseHeader,
    OpenSourceAppItemVue
  },
  data () {
    return {
      items: ItemsData
    }
  }
})
